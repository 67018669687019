let top = 0;
let windowWidth = window.innerWidth;
getTop();
window.addEventListener(
  'resize',
  () => {
    windowWidth = window.innerWidth;
    getTop();
  },
  true
);
export function getTop() {
  let navContainer = document.getElementById('nav-container');
  if (navContainer) {
    top = navContainer.offsetHeight;
  } else {
    //加载好页面后，获取navbar的高度
    window.addEventListener('load', () => {
      top = document.getElementById('nav-container').offsetHeight;
    });
  }
  // console.log(top);
}
export function scrollToSelection(selection) {
  event.preventDefault();
  const element = document.getElementById(selection);
  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: elementPosition - top + 1,
      behavior: 'smooth',
    });
  }
  return element ? selection : null;
}
