<template>
  <div>
    <div
      id="nav-container"
      :class="
        scrollTop > 10
          ? 'backdrop-blur-bg nav-container shadow-bg'
          : 'nav-container'
      "
      :style="{ backgroundColor: backgroundcolor }"
    >
      <div class="nav-content">
        <img
          :src="
            scrollTop > 87
              ? require('@/assets/img/home/ic-home-logo-blue.png')
              : require('@/assets/img/home/ic-home-logo.png')
          "
          class="nav-content-logo"
        />
        <img
          :src="
            scrollTop > 87
              ? require('@/assets/img/m/nav/ic-nav-right-blue.png')
              : require('@/assets/img/m/nav/ic-nav-right-white.png')
          "
          class="right-nav"
          @click="showRightMenu()"
        />
        <div class="nav-box">
          <!-- <router-link
          v-for="(item, index) in navList"
          :key="index"
          :to="item.path"
          :class="scrollTop > 87 ? 'nav-item-white' : 'nav-item-default '"
          >{{ item.name }}</router-link
        > -->

          <div
            class="nav-item"
            :class="scrollTop > 87 ? 'nav-bar-white' : 'nav-bar-default'"
          >
            <a
              :href="'#' + item.selection"
              @click="scrollTo(item.selection)"
              v-for="(item, index) in navList"
              :key="index"
              :class="{ active: activeSection === item.selection }"
              >{{ item.name }}</a
            >
          </div>

          <div @click="scrollTo('selection6')" class="nav-bar-link-we">
            联系我们
          </div>
        </div>
      </div>
    </div>
    <RightSideMenu
      ref="rightMenu"
      :navList="navList"
      @closeMenu="showRightMenu()"
    />
  </div>
</template>
<script>
import { scrollToSelection } from '@/utils/selection';
import RightSideMenu from './rightSideMenu.vue';
import { debounce } from 'lodash';
export default {
  components: {
    RightSideMenu,
  },
  data() {
    return {
      isClick: false,
      navBarHeght: 0,
      menuShow: false,
      activeSection: 'selection1',
      scrollTop: 0,
      backgroundcolor: 'transparent', // 初始背景颜色为透明
      navList: [
        {
          name: '首页',
          path: '/',
          selection: 'selection1',
        },
        {
          name: '数据处理',
          path: '/dataProcessing',
          selection: 'selection2',
        },
        {
          name: '行业智能体研发',
          path: '/verticalModelDevelopment',
          selection: 'selection3',
        },
        {
          name: '大模型一体机',
          path: '/verticalModelDevelopment',
          selection: 'selection4',
        },
        {
          name: '关于我们',
          path: '/aboutUs',
          selection: 'selection5',
        },
      ],
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.$nextTick(() => {
      this.navBarHeght = document.querySelector('#nav-container').offsetHeight;
    });
    this.debouncedScrollEnd = debounce(this.scrollEnd, 50); // 调整延迟时间
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    showRightMenu() {
      this.menuShow = !this.menuShow;
      this.$refs.rightMenu.isVisible = this.menuShow;
    },
    scrollEnd() {
      this.isClick = false;
      // console.log('滚动结束');
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 根据滚动距离计算背景颜色的不透明度（从 0 到 1 渐变）
      let opacity = Math.min(1, this.scrollTop / 200);
      opacity = opacity <= 0.9 ? opacity : 0.9;
      this.backgroundcolor = `rgba(255, 255, 255, ${opacity})`;
      // console.log('handleScroll', this.backgroundcolor, this.scrollTop);】
      if (this.isClick) {
        setTimeout(() => {
          this.debouncedScrollEnd();
        }, 500);
        return;
      }
      this.updateActiveSection();
    },
    updateActiveSection() {
      const sections = document.querySelectorAll('.section');
      let index = -1;
      // console.log('sections', sections);

      // sections.forEach((section, i) => {
      //   const rect = section.getBoundingClientRect();
      //   console.log('rect', rect);
      //   if (rect.top <= 10 && rect.bottom >= 10) {
      //     index = i;
      //   }
      // });
      let arrDomHeight = [];
      sections.forEach((section, i) => {
        let ids = document.querySelector('#selection' + (i + 1));
        if (ids != null) {
          let idsHeight = ids.offsetTop - this.navBarHeght;
          arrDomHeight.push(idsHeight);
        }
      });
      // console.log('arrDomHeight', arrDomHeight, this.scrollTop);
      for (let j = 0; j < arrDomHeight.length - 1; j++) {
        if (
          this.scrollTop >= arrDomHeight[j] &&
          this.scrollTop < arrDomHeight[j + 1]
        ) {
          index = j;
        } else if (
          j == arrDomHeight.length - 2 &&
          this.scrollTop > arrDomHeight[j + 1]
        ) {
          index = j + 1;
        }
      }

      if (index !== -1) {
        this.activeSection = sections[index].id;
        this.$refs.rightMenu.activeSection = this.activeSection;
      }
    },
    scrollTo(selection) {
      this.isClick = true;
      selection = scrollToSelection(selection);
      this.activeSection = selection;
      console.log('selection', 'scrollTo');
    },
  },
};
</script>
<style scoped>
@import '@/css/pc/nav.css';
@import '@/css/m/nav.css';
@import '@/css/common.css';
/**router-link使用
 */
/* .nav-container {
  @apply fixed justify-center  flex items-center w-full pc:h-[90px] md:h-[47px] z-[100] lg:h-[68px] max-w-1920 h-[57px];
}
.nav-item-default {
  @apply leading-[20px] hover:font-medium text-white pc:mr-[40px] lg:mr-[30px] md:mr-[21px]  md:text-px-10 pc:text-px-14  lg:text-px-13;
}
.nav-item-default.router-link-active {
  @apply border-b-2 divide-solid pc:pb-[5px] lg:pb-[4px] md:pb-[3px];
}
.nav-item-white {
  @apply leading-[20px] hover:font-medium text-headline pc:mr-[40px] lg:mr-[30px] pc:text-px-14  lg:text-px-13 md:mr-[21px]  md:text-px-10;
}
.nav-item-white.router-link-active {
  @apply border-b-2 divide-solid  border-[#46464D] pc:pb-[5px] lg:pb-[4px] md:pb-[3px];
} */
</style>
